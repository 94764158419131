import * as React from "react"
import { Container, Row, Col } from 'react-bootstrap'

import Layout from "../components/layout"

const NotFoundPage = (props) => {
  const { siteData, contactInfo } = props.pageContext

  return (
    <Layout siteData={siteData} seo={{title: '404'}}>
      <Container className="not-front 404-page  mt-5 pt-2">
        <Row>
          <Col md="8" className="pe-5">
            <h1>Sorry, that page doesn't exist. </h1>
            <p>You can take a quiz by going to <a href="/">the main page</a>.</p>
            <p>If you can't find what you are looking for please <a href={contactInfo.uri}>{contactInfo.title}</a></p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
